<template>
  <div id="user-profile-form" class="w-100 py-5 ml-5">
    <v-row class="d-flex justify-center pb-4">
      <v-col cols="12" lg="12" md="12">
        <h1 class="subtitle-1 secondary--font font-weight-large">
          User Profile
        </h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="updateUserProfile" v-model="isFormValid">
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            hide-details="auto"
            outlined
            label="First Name"
            class="required"
            v-model="currentUser.firstName"
            :rules="[required('First Name')]"
          />
        </v-col>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            hide-details="auto"
            outlined
            label="Last Name"
            class="required"
            v-model="currentUser.lastName"
            :rules="[required('Last Name')]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            disabled
            outlined
            label="Email"
            class="required user-email-address"
            hide-details="auto"
            v-model="currentUser.email"
            :rules="[required('Email')]"
          >
            <template #append>
              <email-verification-widget
                :is-loading="verifyEmailLoader"
                :is-email-verified="isEmailVerified"
                @verify-email="onSendVerificationEmail"
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col sm="6" cols="12" md="6" lg="6" class="cursor-not-allowed">
          <v-text-field
            hide-details="auto"
            outlined
            label="Company Name"
            v-model="currentUser.companyName"
          />
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col sm="6" cols="12" md="6" lg="6">
          <v-text-field
            outlined
            hide-details="auto"
            label="Phone Number"
            class="user-phonenumber"
            v-model="currentUser.phoneNumber"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left">
          <v-btn
            type="submit"
            color="dark-black"
            :loading="isLoading"
            :disabled="!isFormValid"
            class="font-weight-bold white--text"
            v-track="'save-profile-changes-submit-btn'"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <snackbar
      @close="closeSnackbar"
      :snackbarValue="!!successMessage"
      :snackBarType="'success'"
    >
      {{ successMessage }}
    </snackbar>
  </div>
</template>

<script>
import { pick, isEmpty } from "@/utils";
import Snackbar from "@/components/shared/Snackbar.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { auth } from "@/services/auth";

import { createUserProfile } from "@/services/users";
import { required } from "@/validators/form-validators";

import { FIREBASE_USER_DETAILS, USER_PROPS } from "@/constants/app";
import { disablePrimaryMfa, updateUserNameCookie } from "@/services/auth";

import EmailVerificationWidget from "@/components/widgets/EmailVerificationWidget.vue";

import { sendEmailVerification } from 'firebase/auth'

/**
 * User profile form
 */
export default {
  name: "UserProfileForm",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    Snackbar,
    EmailVerificationWidget,
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      currentUser: {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        companyName: "",
        password: "Test@123",
      },
      isLoading: false,
      isDisabling: false,
      isFormValid: false,
      successMessage: "",
      changePasswordDrawer: false,
      verifyEmailLoader: false,
      changeMfaPhoneDialog: false,
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    currentUserProfile: {
      handler(value) {
        if (!isEmpty(value)) {
          this.currentUser = pick(value, [...USER_PROPS, "email"]);
        }
      },
      immediate: true,
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      cookieToken: "auth/cookieToken",
      isMfaEnabled: "auth/isMfaEnabled",
      currentUserProfile: "auth/userProfile",
      isEmailVerified: "auth/isEmailVerified",
      primaryMfaDetail: "auth/primaryMfaDetail",
    }),
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    required,
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      updateUserSession: "auth/updateUserSession",
      setCurentUserMfaDetails: "auth/setCurentUserMfaDetails",
    }),
    ...mapMutations({
      updateUserDetails: "auth/SET_USER_PROFILE_DETAILS",
    }),
    /**
     * Toogle mfa phone details dialog box
     * @param {Boolean} val Value to set in {{changeMfaPhoneDialog}} property
     */
    toogleMfaPhoneDialog(val) {
      this.changeMfaPhoneDialog = val;
    },
    /**
     * Remove old mfa of the user
     * @description displays success message after old MFA detail have been removed
     */
    async removeOldMfa() {
      try {
        this.isDisabling = true;

        if (!isEmpty(this.primaryMfaDetail)) {
          await disablePrimaryMfa(this.primaryMfaDetail);
          await Promise.allSettled([
            this.updateUserSession(),
            this.setCurentUserMfaDetails(),
          ]);

          this.setSnackbar({
            value: true,
            message: "Phone Number was successfully updated.",
            type: this.$appConfig.snackbar.snackbarTypes.success,
          });
          this.toogleMfaPhoneDialog(false);
        }
      } finally {
        this.isDisabling = false;
      }
    },
    /**
     * Toogles change password drawer
     */
    toggleDrawer(val) {
      this.changePasswordDrawer = val;
    },
    /**
     * Closes the snackbar
     */
    closeSnackbar() {
      this.successMessage = "";
    },
    /**
     * Updates user name value in the cookie set for user profile
     * details
     * @param {String} email email address of the current user
     */
    async updateUserCookie({ email }) {
      try {
        this.isLoading = true;
        await updateUserNameCookie({ email }, this.cookieToken?.token);
      } catch (error) {
        return Promise.reject(error);
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Updated logged in user profile
     */
    async updateUserProfile() {
      try {
        this.isLoading = true;
        await createUserProfile({
          ...this.currentUserProfile,
          ...this.currentUser,
        });

        await Promise.allSettled([
          this.updateUserDetails(pick(this.currentUser, FIREBASE_USER_DETAILS)),
          this.updateUserCookie(this.currentUser),
        ]);

        this.successMessage = `Profile was successfully updated.`;
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Sends email verification email to user email address
     */
    async onSendVerificationEmail() {
      try {
        if (!this.isEmailVerified) {
          this.verifyEmailLoader = true;

          const actions = { url: window.location.href };
          await sendEmailVerification(auth?.currentUser,actions);

          this.successMessage = `Verification email has been sent to your email.`;
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: error?.message,
          type: this.$appConfig.snackbar.snackbarTypes.error,
        });
      } finally {
        this.verifyEmailLoader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.verify-user-email__content,
.user-email-address,
.user-mfa-phonenumber {
  pointer-events: all !important;
}
</style>
