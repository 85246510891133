<template>
  <div class="enable-mfa-message">
    <snackbar
      @close="closeSnackbar"
      :timeout="1000 * 20"
      :snack-bar-type="snackBarType"
      :snackbar-value="showMessage"
    >
      {{ message }}
    </snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ERROR_MESSAGES } from "@/constants/app";
import Snackbar from "@/components/shared/Snackbar.vue";

import {
  getRefferer,
  hasProp,
  isMaropostProduct,
  isType,
  replaceServerUrl,
} from "@/utils";

/**
 * Computes and shows enable 2FA message if mfa is no enabled for a current user
 * Also computes and show error message to the user that he doesnot exists in a specific marketing cluster when
 * user tries to access that cluster such as [app.maropost.com, sanbox.maropost.com].
 * If user doesnot exists in any of the above mentioned array of domains it shows an error message
 * to the user
 */
export default {
  name: "EnableMfaMessage",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    hasError: { type: Boolean, required: true, default: false },
    doesUserExists: { type: Boolean, required: true, default: false },
    referrerErrorMessage: { type: String, required: true, default: "" },
  },
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { Snackbar },
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      showMessage: false,
      snackBarType: "info",
      message: ERROR_MESSAGES.enableMfa,
    };
  },
  /**
|--------------------------------------------------
| Computed properties
|--------------------------------------------------
*/
  computed: {
    ...mapGetters({
      isMfaEnabled: "auth/isMfaEnabled",
      isReffererMaropostProduct: "auth/isReffererMaropostProduct",
    }),
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    referrerErrorMessage: {
      handler(message) {
        if (message && message?.length > 0 && this.hasError) {
          this.showErrorMessage(message);
        }
      },
      immediate: true,
    },
    /**
     * Watches doesUserExists prop for a change
     */
    doesUserExists(val) {
      if (!val && isType(val, "boolean")) {
        this.showUserDoesNotExistsMessage();
      }
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Show enable mfa info message to the user
     */
    showEnableMfaMessage() {
      const refferer = getRefferer();
      this.message = ERROR_MESSAGES.enableMfa;
      this.snackBarType = this.$appConfig.snackbar.snackbarTypes.info;

      if (refferer && !this.isMfaEnabled && this.hasMfaErrorMessage) {
        return isMaropostProduct(refferer);
      } else return false;
    },
    hasMfaErrorMessage() {
      const { query } = this.$route;
      return (
        hasProp(query, "error") && query?.error === ERROR_MESSAGES.mfaError
      );
    },
    /**
     * Shows error message in snackbar
     */
    showErrorMessage(message) {
      this.message = message;
      this.showMessage = true;
      this.snackBarType = this.$appConfig.snackbar.snackbarTypes.error;
    },
    /**
     * Show user doesnot exists in a marketing cluster error message
     * to the user
     */
    showUserDoesNotExistsMessage() {
      if (!this.isReffererMaropostProduct) return;

      let appDomain = getRefferer() ?? "";
      appDomain = appDomain ? new URL(appDomain).host : "";
      if (appDomain) appDomain = replaceServerUrl(appDomain);

      const message = ERROR_MESSAGES.userDoesNotExists.replace(
        "{{domain_name}}",
        appDomain
      );

      this.showErrorMessage(message);
    },
    closeSnackbar() {
      this.showMessage = false;
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.showEnableMfaMessage() && (this.showMessage = true);

    // Show user doesnot have access or doesnot exists in reffered cluster
    if (!this.doesUserExists) this.showUserDoesNotExistsMessage();
  },
};
</script>
