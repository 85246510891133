import { mapActions, mapMutations } from "vuex";
import { isEmpty, pick } from "@/utils";
import { auth } from "@/services/auth";
import { onAuthStateChanged, setPersistence, browserLocalPersistence} from 'firebase/auth'

import { FIREBASE_USER_DETAILS } from "@/constants/app.js";

/**
 * Firebase utilty mixin to used to fetch current user details
 * @author {Jatin Kamboj}
 */
export default {
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      firebaseUser: null,
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapMutations({
      updateUserDetails: "auth/UPDATE_CURRENT_USER_DETAILS",
    }),
    ...mapActions({
      logoutUser: "auth/logoutUser",
    }),
    /**
     * Persists firebase user in the browser tab
     */
    async persistFirebaseUser() {
      await setPersistence(auth,browserLocalPersistence);
    },
    /**
     * fecthes details of current logged in firebase user
     */
    fetchCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(auth,(user) => {
          unsubscribe();
          try {
            if (user && !isEmpty(user)) {
              this.firebaseUser = pick(user, FIREBASE_USER_DETAILS);
              this.updateUserDetails(this.firebaseUser);
              resolve(user);
            } else {
              this.logoutUser();
              console.log("User Logged out successfully ...");
            }
          } catch (error) {
            reject(error);
          }
        }, reject);
      });
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    if (auth.currentUser) {
      await this.fetchCurrentUser();
    } else {
      this.firebaseUser = auth.currentUser;
    }
  },
};
