<template>
  <v-tooltip
    top
    allow-overflow
    color="transparent"
    :close-delay="3000"
    v-model="showTooltip"
    content-class="verify-user-email__content"
  >
    <template #activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" class="cursor-pointer">
        {{ emailVerificationConfig.icon }}
      </v-icon>
    </template>

    <v-alert
      dense
      class="body-2 elevation-4 mb-1"
      :color="emailVerificationConfig.alertColor"
      :class="
        emailVerificationConfig.showVerificationBtn ? 'px-4 py-2' : 'pa-3'
      "
    >
      <template #prepend>
        <v-icon class="pr-2" :color="emailVerificationConfig.iconColor">
          {{ emailVerificationConfig.icon }}
        </v-icon>
      </template>
      <div class="d-flex align-center">
        <div class="text">
          {{ emailVerificationConfig.text }}
        </div>
        <v-btn
          text
          color="dark"
          :loading="isLoading"
          class="font-weight-bold"
          @click.stop="emailVerificationConfig.onClick"
          v-if="emailVerificationConfig.showVerificationBtn"
        >
          Verify
        </v-btn>
      </div>
    </v-alert>
  </v-tooltip>
</template>

<script>
import { isType } from "@/utils";
/**
 * Email verification snackbar widget
 * @description Inicates that user email is verfied
 * User can trigger email verification and an email will be sent to user email address
 * @author {Jatin Kamboj}
 */
export default {
  name: "EmailVerificationWidget",
  /**
  |--------------------------------------------------
  | Custom events 
  |--------------------------------------------------
  */
  emits: ["verify-email"],
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    /**
     * Determines whether email is verifed or not
     */
    isEmailVerified: { type: Boolean, default: false, required: true },
    /**
     * Show loader in verify email button for email verification
     */
    isLoading: { type: Boolean, default: false, required: true },
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      showTooltip: false,
    };
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    /**
     * Closes the tooltip once verification email have been sent to the user
     */
    isLoading(val) {
      if (!val && isType(val, "boolean")) {
        this.showTooltip = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    /**
     * Alert icon color
     * @type {String}
     */
    iconColor() {
      const { commonColors } = this.$appConfig;
      return this.isEmailVerified ? commonColors.success : commonColors.error;
    },
    /**
     * Alert background color
     * @type {String}
     */
    alertColor() {
      const { successLight, errorLight } = this.$appConfig.commonColors;
      return this.isEmailVerified ? successLight : errorLight;
    },
    /**
     * Email verification widget config
     * @description configutaion depends upon the email verified value
     */
    emailVerificationConfig() {
      return {
        text: this.isEmailVerified
          ? "This email address is verified."
          : "This email address is not verified.",
        icon: this.isEmailVerified ? "verified" : "mdi-information-outline",
        iconColor: this.iconColor,
        alertColor: this.alertColor,
        onClick: !this.isEmailVerified ? this.verifyEmail : () => {},
        showVerificationBtn: !this.isEmailVerified,
      };
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * @emits verify-email event in parent component for email verification initiation
     */
    verifyEmail() {
      this.$emit("verify-email");
    },
  },
};
</script>
